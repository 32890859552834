import { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { AutoComplete } from 'antd';
import { autosuggestUnbxd } from 'actions/search';

import { dataLayerHandleEvent } from 'utils/dataLayers';
import { KEYCODES } from 'utils/constants/ux';
import { updateQuerystringParam, getLang, getMarket, getRealLanguage, getCookie, COOKIES } from 'utils/helpers';

import { i18nContext } from 'context/i18n';
import { useIsMounted } from 'hooks';

import AutoSuggest from './autoSuggest';
import styles from './style.module.css';

const minSearchLength = 1;
let timeoutHandler = null;
let blurElementTimeout = null;

function SearchTNM() {
  const { t, locale, setSearchQuery, searchQueryShadow, setSearchQueryShadow, profileData, unbxdSearchResults, setUnbxdSearchResults } =
    useContext(i18nContext);
  const [value, setValue] = useState('');
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [autosuggestVisible, setAutosuggestVisible] = useState(false);
  const isMounted = useIsMounted();
  const inputRef = useRef(null);
  const searchRef = useRef(null);

  const possibleEmptyValuesOfT = ['["",""]', '["buscador.unbxd.api.key","buscador.unbxd.site.key"]'];

  const unbxdTranslations = useMemo(() => [t('buscador', 'unbxd.api.key'), t('buscador', 'unbxd.site.key')], [t]);
  const areTranslationsEmpty = possibleEmptyValuesOfT.some((item) => JSON.stringify(unbxdTranslations) === item);
  const newSearch = profileData?.config?.unbxd === true && !areTranslationsEmpty;

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const searchValue = params.searchTerm;
    if (searchValue && searchValue !== '') {
      setValue(searchValue);
    }
    if (process.browser) {
      window.triggerSearch = (searchStr) => {
        if (!isMounted()) return;

        if (!window.location.pathname.includes('/search')) {
          window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${searchStr}`);
        } else {
          updateQuerystringParam(window.location.search, 'searchTerm', searchStr);
          updateQuerystringParam(window.location.search, 'target', 'A');
          updateQuerystringParam(window.location.search, 'serp', 'new');
          setSearchQuery(searchStr);
          setValue(searchStr);
          document.querySelector('#unbxd-search-box').value = searchStr;
        }
      };

      window.hideAutosuggest = () => {
        setAutosuggestVisible(false);
      };
    }
  }, []);

  const checkIsOutsideSearch = (e) => {
    const isOutsideSearch = e.target.closest('#search-test-b') === null;

    if (isOutsideSearch) {
      setAutosuggestVisible(false);
    }
  };

  const checkAutosuggestInViewport = () => {
    const autoSuggestWidget = document.getElementById('autosuggest-widget');

    if (autoSuggestWidget) {
      const dimensions = autoSuggestWidget.getBoundingClientRect();
      const { top, bottom } = dimensions;

      const isVisible = top >= 0 && bottom <= (window.innerHeight || document.documentElement.clientHeight);

      if (!isVisible) {
        setAutosuggestVisible(false);
      }
    }
  };

  useEffect(() => {
    if (autosuggestVisible === true) {
      document.addEventListener('mousedown', checkIsOutsideSearch);
      document.addEventListener('scroll', checkAutosuggestInViewport);
      // document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('mousedown', checkIsOutsideSearch);
      document.removeEventListener('scroll', checkAutosuggestInViewport);
      // document.body.style.overflow = 'inherit';
    }
  }, [autosuggestVisible]);

  const onSearch = () => {};

  const blurActiveElement = () => {
    document.activeElement.blur();
  };

  const letsSearch = useCallback(
    async (locale, searchStr) => {
      if (!searchStr || searchStr.trim().length < minSearchLength) {
        return;
      }
      setSearchQueryShadow(searchStr);

      const lang = getLang(locale);
      const isoLanguage = getRealLanguage(lang);
      const unbxdUid = getCookie([COOKIES.UNBXD_UID]) || null;
      const results = await autosuggestUnbxd(isoLanguage, getMarket(locale), searchStr, unbxdTranslations, unbxdUid);
      // llamamos al API de unbxd de autosuggest
      // obtenemos respuesta y mostramos modal autosuggest
      // da CORS, usamos respuesta mockup

      setUnbxdSearchResults(results);

      clearTimeout(blurElementTimeout);
      blurElementTimeout = setTimeout(blurActiveElement, 1000);

      dataLayerHandleEvent({
        event: 'virtualPage',
        pageName: window.location.pathname + window.location.search,
      });
    },
    [setSearchQueryShadow, setUnbxdSearchResults, unbxdTranslations],
  );

  const handleKeyUp = async (event) => {
    const searchStr = event.target.value;

    if (event.code === KEYCODES.enter) {
      if (!isMounted()) return;

      event.target?.blur();
      if (!window.location.pathname.includes('/search')) {
        window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${searchStr}`);
      } else {
        updateQuerystringParam(window.location.search, 'searchTerm', searchStr);
        updateQuerystringParam(window.location.search, 'target', 'A');
        updateQuerystringParam(window.location.search, 'serp', 'new');
        setSearchQuery(searchStr);
      }
    } else {
      clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        letsSearch(locale, searchStr);
      }, 250);
    }
  };

  const onChange = (data) => {
    setValue(data);
  };

  const onSelect = (data) => {
    window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${data}`);
  };

  const onClickExpandIcon = () => {
    const tnmMobile = document.querySelector('.tnm--mobile');
    const filterNav = document.querySelector('#plp-filter-nav');
    const searchInput = document.querySelector('.tnm--mobile [class*="searchInput"]:not(.tnm__modal *)');
    const tnmModal = document.querySelector('.tnm__modal');
    let forcedCloseTnm = false;
    if (tnmModal && tnmModal.style.display !== 'none') {
      forcedCloseTnm = true;
      document.querySelector('.tnm__modal .tnm__close').click();
    }
    if (tnmMobile) {
      if (tnmMobile.style.height === '54px' || tnmMobile.style.height === '' || forcedCloseTnm) {
        tnmMobile.style.height = '100px';
        setExpandedSearch(true);
        if (inputRef?.current) {
          if (searchInput && searchInput.style) {
            searchInput.style.display = 'inline-block';
            if (forcedCloseTnm) {
              document.querySelector('.tnm--mobile [class*="searchInput"]:not(.tnm__modal *)').style.top = '0px';
              inputRef.current.focus();
            }
          }
          inputRef.current.focus();
        }
      } else {
        tnmMobile.style.height = '54px';
        setExpandedSearch(false);

        if (searchInput && searchInput.style) {
          searchInput.style.display = 'none';
        }

        if (filterNav?.style?.top === '100px') {
          filterNav.style.top = '50px';
        }
      }
    }
  };

  const onClickSearchIcon = () => {
    if (!window.location.pathname.includes('/search')) {
      window.location.assign(`${window.location.origin}/${locale}/search?target=A&searchTerm=${searchQueryShadow}`);
    } else {
      updateQuerystringParam(window.location.search, 'searchTerm', searchQueryShadow);
      updateQuerystringParam(window.location.search, 'target', 'A');
      setSearchQuery(searchQueryShadow);
    }
  };
  const onFocus = () => {
    const currentValue = document.activeElement.value;

    if (currentValue !== value) {
      setValue(currentValue);
    }
  };

  useEffect(() => {
    if (!['', null].includes(value)) {
      // hacemos esto porque el searchtnm está 3 veces en la web por cosas del tnm
      const isVisible = searchRef?.current?.checkVisibility();

      if (isVisible) {
        letsSearch(locale, value);
      }
    }
  }, [locale, searchRef, letsSearch, value]);

  return (
    <div className={styles.wrapper}>
      {newSearch ?
        <div
          onFocus={() => {
            setAutosuggestVisible(true);
          }}
          id="search-test-b"
          ref={searchRef}
          className={`${styles.container} ${expandedSearch ? styles.expanded : styles.hidden}`}
        >
          <img className={styles.searchIcon} src="/assets-new/search.svg" width={30} height={30} alt="Search" onClick={onClickExpandIcon} />
          <AutoComplete
            id="unbxd-search-box"
            ref={inputRef}
            value={value}
            onClick={() => setAutosuggestVisible(true)}
            onSearch={onSearch}
            onKeyUp={handleKeyUp}
            onChange={onChange}
            onSelect={onSelect}
            placeholder={t('generico', 'input.placeholder', 'Search')}
            className={styles.searchInput}
            virtual={false}
            dropdownClassName={styles.searchDropdown}
            allowClear={false}
            backfill
            notFoundContent={null}
            showArrow
            suffixIcon={<img className={styles.suffixIcon} src="/assets-new/search.svg" width={17} height={17} alt="Search" onClick={onClickSearchIcon} />}
            onFocus={onFocus}
          ></AutoComplete>
          <AutoSuggest query={value} data={unbxdSearchResults} visible={autosuggestVisible} setVisible={setAutosuggestVisible} />
        </div>
      : <div id="search-test-a" className={`${styles.searchV1}`}>
          <a href={`/${locale}/search`}>
            <input className={styles.inputV1} type="text" placeholder={t('generico', 'input.placeholder', 'Search')} />
            <img className={styles.searchIconV1} src="/assets-new/search.svg" width={17} height={17} alt="Search" />
          </a>
        </div>
      }
    </div>
  );
}

SearchTNM.propTypes = {};

SearchTNM.defaultProps = {};

export default SearchTNM;
