import { useContext, useEffect } from 'react';
import { string, object, bool } from 'prop-types';

import { i18nContext } from 'context/i18n';
import { getIsMobile, capitalize, getLang, getMarket, getRealLanguage } from 'utils/helpers';

const AutoSuggest = ({ query, data, visible, setVisible }) => {
  const { t, locale, unbxdInitialSearch } = useContext(i18nContext);
  const isMobile = getIsMobile('1200px');
  const lang = getLang(locale);
  const market = getMarket(locale);

  if ([undefined, null].includes(data) || visible === false) {
    return null;
  }

  const isSearchAll = ['', '*'].includes(query);
  const dataToUse = isSearchAll ? unbxdInitialSearch : data;
  const topQueries = dataToUse?.products?.filter((item) => ['TOP_SEARCH_QUERIES', 'KEYWORD_SUGGESTION'].includes(item.doctype)).map((item) => item.autosuggest);
  const products = dataToUse?.products?.filter((item) => item.doctype === 'POPULAR_PRODUCTS');
  const transformRegex = isSearchAll ? null : new RegExp(query, 'g');
  const transformRegexUpperCase = isSearchAll ? null : new RegExp(query.toUpperCase(), 'g');
  const transformRegexCapitalize = isSearchAll ? null : new RegExp(capitalize(query), 'g');
  const isoLang = `${getRealLanguage(lang)}-${market}`;

  const handleProductsScroll = () => {
    document.activeElement.blur();
  };

  return (
    <div className={isMobile ? 'unbxd-autosuggest-mobile' : 'unbxd-autosuggest-desktop'}>
      <div
        id="autosuggest-widget"
        className={`unbxd-as-wrapper ${isMobile ? 'unbxd-as-mobile' : 'unbxd-as-desktop'} unbxd-as-extra-right fixed left-0 top-[99px] z-50 flex !h-[70vh] !flex-col !overflow-y-hidden bg-white p-[16px] pt-[19px] shadow-md tablet:absolute tablet:left-auto tablet:right-0 tablet:top-[37px] tablet:!h-[614px] tablet:w-[700px] tablet:!flex-row-reverse`}
      >
        {dataToUse !== false ?
          <>
            <ul onScroll={handleProductsScroll} class={`${isMobile ? 'unbxd-as-maincontent' : 'unbxd-as-sidecontent'} overflow-y-auto`}>
              <li class="unbxd-as-header flex items-center justify-between">
                {t('buscador', 'unbxd.best.results')}

                {/*isMobile && (
                  <div className="cursor-pointer" onClick={() => setVisible(false)}>
                    <CloseSVG />
                  </div>
                )*/}
              </li>
              {products.map((item, index) => {
                return (
                  <li
                    key={`unbxd-product-${index}`}
                    className={`unbxd-as-popular-product unbxd-as-popular-product-grid !float-none tablet:hover:!bg-[#f6f6f6]`}
                    data-value={item.autosuggest}
                    data-src=""
                    data-index={index}
                    data-type={item.doctype}
                    data-pid={item.uniqueId}
                  >
                    <a class="unbxd-as-popular-product-info" href={item[`${market}_${lang}_productUrl`]}>
                      <div class="unbxd-as-popular-product-image-container unbxd-oss-img">
                        <img src={item.imageUrl[0]} class="unbxd-oss-image" alt="Product Image" />
                      </div>
                      <div class="unbxd-oss-name">{item.title}</div>
                      <div class="unbxd-oss-supporttext">{item[`${lang}_product_sheet_h1`]}</div>
                      <div class="unbxd-as-popular-product-price">
                        {item[`${market}_max_sale_price_unx_d`] ?
                          <>
                            <div class="unbxd-sale-price">
                              {item[`${market}_max_sale_price_unx_d`]?.toLocaleString(isoLang, {
                                style: 'currency',
                                currency: item[`${market}_currency_unx_ts`],
                                maximumFractionDigits: 0,
                              })}
                            </div>
                            <div>
                              <span class="unbxd-was-price">
                                {item[`${market}_max_price_unx_d`]?.toLocaleString(isoLang, {
                                  style: 'currency',
                                  currency: item[`${market}_currency_unx_ts`],
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              <span class="unbxd-discount"> -{item[`${market}_sale_percentage`]}%</span>
                            </div>
                          </>
                        : <div class="unbxd-sale-price">
                            {item[`${market}_max_price_unx_d`]?.toLocaleString(isoLang, {
                              style: 'currency',
                              currency: item[`${market}_currency_unx_ts`],
                              maximumFractionDigits: 0,
                            })}
                          </div>
                        }
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
            {!isMobile && (
              <ul class="unbxd-as-maincontent">
                <li class="unbxd-as-header main-section-header">{t('buscador', 'unbxd.suggestions')}</li>
                {topQueries.map((item, index) => {
                  const transformedQuery =
                    isSearchAll ? item : (
                      item
                        .replace(transformRegex, `<b>${query}</b>`)
                        .replace(transformRegexUpperCase, `<b>${query.toUpperCase()}</b>`)
                        .replace(transformRegexCapitalize, `<b>${capitalize(query)}</b>`)
                    );

                  return (
                    <li key={`top-query-${index}`} class="unbxd-as-topqueries" data-type="TOP_SEARCH_QUERIES" data-index={index} data-value={item}>
                      <span
                        onClick={() => {
                          window.location.assign(`${window.location.origin}/${locale}/search?searchTerm=${item}&target=A`);
                        }}
                        dangerouslySetInnerHTML={{ __html: transformedQuery }}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            <a
              href={`${window.location.origin}/${locale}/search?target=A&searchTerm=${query}`}
              className="bottom-[0px] left-[50%] flex w-[75%] items-center justify-center self-center border-[1px] border-black p-[10px] align-middle text-[14px] uppercase tablet:absolute tablet:bottom-[25px] tablet:right-[25px] tablet:ml-auto tablet:min-w-[155px] tablet:max-w-[200px] tablet:px-[15px] tablet:py-[10px]"
            >
              {t('buscador', 'unbxd.see.all')}
              <span className="ml-2">{'>'}</span>
            </a>
          </>
        : <span>
            {t('buscador', 'no.results.for')} {query}
          </span>
        }
      </div>
    </div>
  );
};

AutoSuggest.propTypes = {
  data: object,
  query: string,
  visible: bool,
};

AutoSuggest.defaultProps = {};

export default AutoSuggest;
